import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const LegalPage = ({ data }) => {
    return (
        <Layout>
            <SEO title="Legal" />
            <div css={css`
                padding: 1.5rem;

                @media (min-width: 648px) {
                    padding: 1.5rem 0;
                }

                h1 {
                    font-size: 28px;
                    line-height: 32px;
                    margin-bottom: 13px;
                    margin-top: 2px;

                    &:not(:first-child) {
                        margin-top: 16px;
                    }
                }

                font-size: 18px;
                line-height: 28px;
                margin-bottom: 0;

                p {
                    margin-bottom: 12px;
                    &:last-child {
                        margin-bottom: -6px;
                    }
                }

                p.legal {
                    margin-top: 14px;
                    font-size: 16px;
                    line-height: 16px;
                    &:last-child {
                        margin-bottom: -4px;
                    }
                }
            `}>
                {data.allShopifyShopPolicy.nodes.map(policy => (
                    <div css={css`

                    `}>
                        <h1>{policy.title}</h1>
                        <div dangerouslySetInnerHTML={{__html: policy.body}}></div>
                    </div>
                ))}
            </div>
        </Layout>
    )
}

export default LegalPage

export const query = graphql`
{
    allShopifyShopPolicy {
        nodes {
            id
            body
            title
        }
    }
}  
`